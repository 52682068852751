.bs__creds_parent_container {
  display: flex;
  justify-content: start;
  align-items: end;
  width: 100%;
  padding: 10px 0px;
}

.bs__creds_tier_left {
  position: relative;
  width: 3%;
  height: 100%;
}

.bs__creds_tier_step {
  width: 97%;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.bs__creds_tier_left_border {
  height: 120px;
  border-left: 0.1px dashed #3361cf;
  margin-left: 30%;
}

.bs__creds_tier_left_border::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #3361cf content-box;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.bs__creds_tier_right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 14px;
}

.bs__creds_tier_badge {
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 90%;
  padding: 0 0 5px 20px;
  position: relative;
  gap: 10px;
}

.bs__creds_tier_badge_border {
  border-left: 0.1px dashed #3361cf;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 30px;
  z-index: 1;
}

.bs__creds_tier_badge_text {
  font-size: 12px;
  color: #3361cf;
  font-weight: 600;
}

.bs__creds_tier_tasks_count {
  width: 100%;
  height: 35px;
  background-color: rgba(90, 131, 231, 0.9);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  position: relative;
}
.bs__creds_tier_tasks_count p {
  font-size: 12px;
  padding-left: 30px;
  z-index: 100;
}

.bs__creds_tier_title {
  font-size: 14px;
  color: #3361cf;
  word-wrap: break-word;
  display: inline-block;
  margin-bottom: 5px;
  width: max-content;
  font-weight: 600;
}

.bs__creds_avatars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
}

.bs__creds_task_badge_user_text {
  font-size: 10px;
  color: #5c5c5c;
}

.bs__creds_tier_task_count_zebra {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: absolute;
  transform: rotate(45deg);
}

.bs__creds_tier_task_count_zebra div {
  height: 500px;
  flex-shrink: 0;
  display: flex;
  width: 5px;
  background-color: rgba(90, 131, 231, 0.9);
  opacity: 0.6;
}
