.bs__container {
  background: linear-gradient(
    91.81deg,
    rgba(138, 115, 219, 0.15) 0%,
    rgba(90, 131, 231, 0.15) 51.56%,
    rgba(138, 115, 219, 0.15) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.bs__tab_panel_container {
  padding: 15px;
}

.bs__task_based_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 40px;
  overflow-y: auto;
}
.bs__point_based_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 100%;
  gap: 10px;
  overflow-x: auto;
}

.bs__container button.Mui-selected {
  background: linear-gradient(99.09deg, #3361cf 0%, #8a73db 100%);
  border-radius: 0px 0px 10px 10px;
  color: white;
}

.bs__container span.MuiTabs-indicator {
  background-color: transparent !important;
}

.bs__container *::-webkit-scrollbar {
  height: 12px !important;
  background-color: #fff;
  border-radius: 10px;
}

.bs__container *::-webkit-scrollbar-thumb {
  height: 5px !important;
  background-color: #5a83e7;
  border-radius: 10px;
  border: 3px solid white;
}
