.tourModal {
     display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 30px;
    border-radius: 10px;
}
 
.tourModal u{
     cursor: pointer;
}

 
.tourModal__actions{
display: flex;
justify-content: space-between;
align-items: center;
}
.tourModal__actions div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    }

    .tourModal__actions div h4{
     cursor: pointer;
    }