.adminLayout {
  background-image: url("../../../assets/Bg.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.dashboard__actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard__content {
  display: flex;
  flex-direction: column;
  gap: 30px;

  padding: 5px;
  padding-top: 20px;
}
