.employee__page_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.employee__page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0 50px 0;
}

.tableUi__head_sortable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.employee__page_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.employee__phone_input {
  padding: 8px;
  border: 1px #c3c3c3 solid;
  border-radius: 5px;
  margin-top: 4px;
  width: -webkit-fill-available;
}

.employee__phone_input input {
  border: none;
  outline: none;
  width: 100%;
}

.employee__modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 15px;
}

.employee__modal_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.employee__bulk_upload_label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed #c3c3c3;
  min-height: 190px;
  margin: 20px 0 60px 0;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
}

.employee__bulk_upload_label:hover {
  background-color: #3361cf10;
  border: 2px dashed #3361cf;
}

.employee__bulk_upload_action_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.employee__bulk_upload_label_text {
  font-size: 16px;
  color: #747474;
  margin: 10px 0 5px 0;
}

.employee__bulk_upload_label_text_sm {
  font-size: 12px;
  color: #747474;
}

.employee__form_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px 50px;
  padding: 60px 0;
}

.employee__permissions_view_btn {
  margin-left: auto !important;
}

.employee__permission_item {
  display: flex;
  align-items: center;
}

.employee__manage_employee_dd {
  position: relative;
  /* z-index: 1; */
  width: max-content;
  font-size: 14px !important;
}

.employee__manage_employee_dd_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 3px 14px;
  background-color: var(--pwhite);
  border-radius: 5px;
  border: thin solid var(--grey300);
  cursor: pointer;
  z-index: 1;
  transition: height 0.5s;
  margin-right: -2px;
}

.employee__manage_employee_dd_item:hover {
  background-color: #f2f6fc;
  border: thin solid var(--pblue);
}

.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 2.5px 14px 2.5px;
  background-color: var(--pwhite);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0.5px solid var(--grey400);
  border-bottom: none;
  cursor: pointer;
  z-index: 1;
  transition: height 0.5s;
}

.employee__manage_employee_dd_item p {
  /* font-size: 14px; */
  padding: 7px 12px;
  cursor: pointer;
  color: var(--grey400);
  text-transform: capitalize;
}
.employee__manage_employee_dd_item p:not(:first-child):hover {
  background-color: #3361cf11;
}

.select_divider {
  height: 0.5px;
  width: 90%;
  background-color: #c5c5c5;
  margin: 0 auto;
}

.employee__manage_employee_dd_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.employee__manage_employee_dd_label p {
  padding: 5px 0;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee__manage_employee_dd_label img {
  width: 13px;
  margin-left: 4px;
}

.employee__select_menu {
  position: absolute;
  top: 100%;
  background: white;
  z-index: 10;
  width: 100%;
  /* height: 100%; */
  max-height: 215px;
  overflow-y: auto;
  left: 0;
  border: 0.5px solid var(--grey400);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
}
.employee__select_menuPara{
  width: 170px;
   line-clamp: 1;
  box-orient: vertical;
  max-width: 100%;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }

.employee__manage_employee_dd p {
  font-size: 14px;
}
.employee__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 300px);
}

.MuiSelect-select {
  padding: 10px !important;
}


 