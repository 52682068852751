.accessBottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 30px;
  border-radius: 20px;
  border: 0.5px solid var(--grey300);
}

.tableUi__head_sortable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
