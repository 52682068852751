.themes {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.themes__card {
  border: 1.5px solid var(--grey300);
  padding-bottom: 30px;
  border-radius: 7px;
  background: linear-gradient(
    180deg,
    rgba(195, 195, 195, 0) -42.33%,
    rgba(195, 195, 195, 0) -42.32%,
    #d9d9d9 100%
  );
  backdrop-filter: blur(2px);
  border-radius: 10px;
  width: 100%;
  max-width: 270px;
}
.themes__card:hover {
  scale: 1.1;
  transition: scale 0.3s ease-in-out;
  border: 1.5px solid var(--grey600);
}

.themes__img img {
  width: 100%;
  max-width: 270px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.themes h6 {
  max-width: 220px;
  height: 17px;
  overflow: hidden;
}

.themes__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}

.themes__applied {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  gap: 5px;
  border-radius: 5px;

  background-color: var(--pblue);
}
.themes__applied h6,
.themes__applied .themes__icons {
  color: white;
}

.themes__actionscontainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.themes__actions:hover {
  background-color: var(--pblue);
}
.themes__actions h6,
.themes__icons {
  color: var(--pblue);
}
.themes__icons {
  font-size: 18px !important;
}

.themes__actions:hover h6,
.themes__actions:hover .themes__icons {
  color: var(--pwhite);
}

.themes__containeroverlay {
  position: absolute;
  display: none;
}

.themes__img:hover .themes__containeroverlay {
  display: unset;
}

.themes__icon {
  color: "white" !important;
}

.theme__name {
  margin: 20px 0px 20px 30px;
}
