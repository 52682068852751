.saadminLayout {
  background-image: url("../../../assets/Bg.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.sadashboard__actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.sadashboard__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
  padding-top: 20px;
}

.questLeaderboard h3 {
  color: white;
  background: linear-gradient(
    90deg,
    #3361cf 0%,
    rgba(51, 97, 207, 0.15) 101.07%
  );
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: 500;
}
