.task {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  padding-bottom: 45px;
}

.task__card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 350px;
  height: 240px;
  background-color: white;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  gap: 20px;
  transition: height 1s;
}

.task__head {
  display: flex;
  gap: 20px;
  align-items: center;
}

.task__headprofile {
  position: relative;
}
.task__headnotf {
  position: absolute;
  background-color: var(--error);
  /* padding: 2px; */
  color: white;
  /* font-size: 8px; */
  width: 19px;
  height: 19px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 11px;
  font-weight: 700;
  top: 3px;
  border-radius: 50%;
}

.task__head h3 {
  color: var(--grey500);
  font-weight: 500;
}

.task__head h4 {
  margin-top: 3px;
  color: var(--grey400);
}

.task__books {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.task__no {
  color: var(--pblue);
}

.task__bookshead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.task__bookscard {
  width: 51px;
  padding: 3px 0px;
  background-color: var(--pblue);
  color: white;
  display: flex;
  justify-content: center;
}

.task__bookscardIA {
  width: 51px;
  padding: 3px 0px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.task__bookscontent {
  display: flex;
  gap: 1px;
  border-radius: 5px;
  border: 5px solid var(--grey200);
}

.task__bookscard caption {
  color: white;
  font-size: 9px;
  font-weight: 500;
}
.task__bookscardIA caption {
  color: var(--pblue);
  font-size: 9px;
  font-weight: 500;
}

.task__viewAll {
  margin-left: auto;
}

.task__full {
  animation: fade-in 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
.task__layout {
  overflow-y: auto;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.task__full img {
  max-width: 370px;
  border-radius: 5px;
}
.task__fullhead,
.task__fullhead h3 {
  display: flex;
  justify-content: space-between;
}
.task__fullhead h4 {
  max-width: 300px;
}

.task__fullhead div {
  justify-self: flex-end;
}
.task__fulldecp h3 {
  color: var(--grey600);
  margin-bottom: 5px;
}
.task__fulldecp h4 {
  color: var(--grey500);
}
.task__fullaction,
.task__fullfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.task__fullfooter h5 {
  cursor: pointer;
}
