.accessHead {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 30px;
  border-radius: 20px;
  border: 0.5px solid var(--grey300);
}

.accessHead__corousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  padding: 0px 5px;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f4f4f4;
  /* Adjust the height and padding as needed */
  /* height: 100px; */
  position: relative;
}

/* Style the scrollbar */
.accessHead__corousel::-webkit-scrollbar {
  display: none;
}

/* .accessHead__corousel::-webkit-scrollbar-track { */
/* background-color: #000000; */
/* display: none; */
/* } */

.accessHead__corousel::-webkit-scrollbar-thumb {
  display: none;
}

.accessHead__left_btn,
.accessHead__right_btn {
  position: absolute;
  top: 43%;
  flex-shrink: 0;
  border-radius: 100%;
  padding: 2px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: #00000065;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  z-index: 100;
}

.accessHead__left_btn {
  left: -17px;
}

.accessHead__right_btn {
  right: -17px;
}

.accessHead__left_btn:hover,
.accessHead__right_btn:hover {
  background-color: #00000099;
}

.accessHead__panel:hover .accessHead__left_btn,
.accessHead__panel:hover .accessHead__right_btn {
  opacity: 1;
}
