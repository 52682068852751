.shop{
    padding: 20px 0px 80px;
}
.shop__head{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.shop__dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
     cursor: pointer;
    position: relative;
    width: 130px;
    
}
.shop__dropdownChild{
background-color: white;
position: absolute;
top: 90%;
left: 0%;
 opacity: 0;
display: flex;
/* justify-content: center; */
flex-direction: column;
padding: 5px 0;
width: 180px;
height: 150px;
/* max-height:100% ; */
 overflow-y: auto;
border: 1px solid var(--grey300);
border-radius: 5px;
}

.shop__dropdown:hover {
    color: var(--pblue);
}

.shop__dropdown:hover .shop__dropdownChild, .shop__dropdownChild:hover{
    opacity: 1;
}
.shop__dropdownChild h5{
    font-weight: 400;

    padding: 3px 6px;
}

.shop__dropdownChild h5:hover{
background-color: rgb(238, 238, 238);
}


.shop__cards{
    display: flex;
    /* justify-content: space-around; */
    gap: 40px;
    margin: 40px 0px;
    flex-wrap: wrap;
 }

/* Card */

.shopCard{
    width: 210px;
    height: 100%;
    padding-bottom: 5px;
    border-radius: 6px;
    border: 1px solid var(--grey300);
    background-color: white;
}

.shopCard img{
border-radius: 5px 5px 0px 0px;
width: 100%;
max-width: 210px;
height: 170px;
 object-fit: cover;
}




    .shopCard__heading p{
        width: 170px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;}
/* 
        .shopCard__heading p:hover {
            width: 80px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;} */
    
.shopCard__heading{
    display: flex;
    justify-content: start;
    text-align: left;
    align-items: start;
    flex-direction: column;
    padding:  15px;
}
.shopCard__heading div{
    display: flex;
    gap: 3px;
    align-items: center;
}

.shopCard__action{
    padding:  0px 15px 10px 15px;
}


.cart_product_table_cell{
    padding-left: 20px !important;
}


 