.feedback{
 position: absolute;
 cursor: pointer;
  width: 50px;
  height:  50px;
  bottom: 30px;
  right: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: whiteSmoke;
  background-color: var(--pmain);
  z-index: 99999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* box-shadow: 4px -40px 60px 5px rgb(26, 117, 206) inset; */

}
.feedback__anim{
  animation: hithere 1s ease infinite;

}
 
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

