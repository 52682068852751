.certificatemodal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.certificatemodal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}

.certificatemodal__cross {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
