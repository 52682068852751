.graphCards {
  width: 330px;
  height: 100%;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  overflow: hidden;
}
.graphCards__details {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  height: 110px;
  align-items: center;
}

.graphCards__detailscount {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.graphCards__content {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
}

.graphCards__content h6 {
  margin-right: 15px;
}

.graphCards__bottom {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 3px 0px 3px 15px;
  background-color: var(--slightYellow);
  gap: 5px;
  border-radius: 5px;
}
.graphCards__bottom h5 {
  color: var(--pblue);
}

.graphCards h5 {
  color: var(--grey400);
}

.graphCards__contentValues {
  margin-left: auto;
}

.graphCards h6 {
  font-size: 11px;
}

.popperOver {
  background-color: var(--slightYellow) !important;
  font-size: 10px !important;
  color: var(--grey400);
  position: absolute;
  width: 50%;
  right: 10.5px;
  top: 15%;
  padding: 8px;
  font-weight: 500;
  border-radius: 5px;
  line-height: 1.5;
}
.popperOver span {
  font-weight: 400;
  color: var(--grey400);
  line-height: 1.5;
  font-size: 10.5px !important;
}
.popperOver h6 {
  color: var(--pblue);
  font-size: 10.5px;
}

.graphCards__chart {
  position: relative;
  width: 110px;
  height: 110px;
}

.graphCards__inner-shadow {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}


 
 
 

 
 



.graphCards__selectLayout{
  position: relative;
}

.graphCards__selectActive {
  background-color: white;
  border: 0.5px solid var(--grey300);
  width: 97px;
   border-radius: 3px;
  z-index: 99;
  height: 130px;
  overflow-x: hidden;
   overflow-y: auto;
  display: flex;
  padding: 2px 0px;
  position: absolute;
  flex-direction: column;
  gap: 20px;
  transition: height 1s;
  
}
.graphCards__select {
display: none;  overflow: hidden;
}
.graphCards__selectcontent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 5px;
  padding: 5px 0px 0px 10px;
}


.graphCards__headLayout{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}
 
.graphCards__headLayout span {
  font-size: 13px;
  
}