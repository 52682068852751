.ccover__img img {
  /* max-width: 40vw; */
  width: 100%;
  max-width: 50vw;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.ccover__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ccover {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ccoverD {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  height: 430px;
  max-height: 60vh;
  padding: 5px 5px 5px 0px;
}
.ccover__middle h4 {
  margin-bottom: 5px;
}
.ccover__middle h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  line-break: anywhere;
}

.ccover__middle h4 {
  overflow: hidden;
  margin-top: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ccover__icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.ccover__icons div,
.ccover__head div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ccover__head{
  position: static;
}
