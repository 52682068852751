.main__wrapper {
  /* height: 100vh; */
  overflow: hidden;
  position: relative;
  width: 100%;
  /* padding: 100px 0px 0px 0px; */
  background: white;
}
.main {
  text-align: left;
  position: relative;
  z-index: 1;
  background: white;
  backdrop-filter: blur(100px);
  /* overflow: hidden; */
  /* height: calc(100vh - 70px); */
  height: 100%;

  max-height: calc(100vh - 70px);
  overflow-y: auto;
}

.main:before {
  content: "";
  position: absolute;
  left: -100%;
  bottom: 0;
  top: 0;
  right: 100%;
  background: rgba(42, 44, 49, 0.85);
  backdrop-filter: blur(100px);
}

/* .dark .main {
  background: rgba(42, 44, 49, 0.85);
  backdrop-filter: blur(100px);
} */

.main__righttop {
  position: absolute;
  width: 50vw;
  height: 50vw;
  right: -271px;
  top: -199px;
  background: #3361cf;
  border-radius: 50%;
  z-index: -3;
  overflow: hidden;
}
.main__left {
  position: absolute;
  width: 50vw;
  height: 50vw;
  bottom: -370px;
  left: -163px;
  border-radius: 50%;
  background: #8a73db;
  /* z-index: -3; */
  overflow: hidden;
}

.layout {
  display: grid;
  grid-template-rows: auto auto;
  height: 100vh;
  width: 100vw;
  background-image: url("../../../assets/books/bgs/christmas\ _\ new\ year\ theme\ background.png");
}
/* .layout__left {
  position: static;
  z-index: 1300;
} */
.layout__right {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100vw;
  margin-top: 70px;
}
