.profile {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.profile__head {
  display: flex;
  justify-content: flex-start;
}
.profile__headcontent {
  width: 100%;
  margin-left: 20px;
}

.profile__headcontent__item {
  margin-top: 5px;
  font-size: large;
}

.profile__view__selector {
  margin-top: 10px;
}
.profile__view__selector p {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.profile {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
* {
  margin: 0;
}
user agent stylesheet div {
  display: block;
}

/* .css-1z2v8n-MuiPaper-root-MuiDrawer-paper {
  background-color: white !important;
} */

.view__selector__bodyItem p {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.MuiAccordionSummary-root {
  min-height: 18px !important;
}

.view__selector__bodyItem {
  cursor: pointer;
  margin-bottom: 15px;
}
.view__selector__bodyItem:hover {
  color: var(--pblue);
}

.view__selector__bodyItem p:hover {
  font-weight: 600 !important;
}

.profile__headcontentoption {
  /* background-color: white; */
  width: 200px;
  background-color: aliceblue;
}

.profile__head h6 {
  font-weight: 600;
  font-size: 14px;
}
.profile__head p {
  font-size: 11px;
  font-weight: 300;
}
.profile__head {
  margin: 10px 0 15px;
}
.profile ul li {
  list-style-type: none;
  margin: 22px 0;
  font-size: 15px;
  font-weight: 300;
  display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.profile ul li:hover {
  color: var(--pmain) !important;
  font-weight: 500;
}

.profile ul {
  padding: 0;
}

.profile__logout ul li:hover {
  color: red !important;
  /* font-weight: 600; */
}
.profile__seprator {
  height: 0.5px;
  background-color: var(--grey400);
  width: 100%;
  margin: 10px 0;
}

.Mui-expanded {
  border-color: var(--pmain) !important;
}
