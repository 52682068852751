.breadcrumbsUi {
  background-color: var(--slightpurple);
  padding: 4px 0px;
  width: 100%;
  border-radius: 5px;
}

/* .dark .MuiBreadcrumbs-separator {
  color: var(--pmain);
} */

.breadcrumbAdmin {
  padding: 4px 0px;
  width: 100%;
  border-radius: 5px;
}
