.books__layout {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh);
  /* background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(0px); */

  /* background-image: url("../../assets/books/bgs/christmas\ _\ new\ year\ theme\ background.png"); */
}
.container__books {
  padding-top: 90px;
}
.books__layout h1 {
  color: white;
}

.books__breadcrumbs {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-bottom: 40px; */
}
/* .books__breadcrumbs h4 {
  color: var(--pmain);
} */
.books__breadcrumbsicon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1.5px solid white;
  position: relative;
}
.books__breadcrumbshome {
  border-radius: 50%;
  width: 30px;
  border: 1.5px solid white;
  height: 30px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.books__breadcrumbshome:hover  .MuiSvgIcon-root{
 color: var(--grey600);
}

.books__breadcrumbshome:hover {
  background-color: white; 
}

.darkBook .books__breadcrumbshome:hover {
  background-color: var(--pblue);
}
.darkBook .books__breadcrumbshome:hover  .MuiSvgIcon-root{
  color: white;
 }

.darkBook .books__breadcrumbshome {
  border-radius: 50%;
  width: 30px;
  border: 1.5px solid var(--pblue);
  height: 30px;
  padding: 3px;
}

.books__breadcrumbs img {
  transform: rotate(-90deg);
  margin: 8px;
}

.books {
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: center;
  height: 60vh;
  margin-top: 3vh;
}
/* .books__lyt {
  place-self: center;
} */

.bg__books {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: relative;
}

.outlined__book_btn {
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  line-height: 17px;
  outline: none;
  /* identical to box height */
  text-transform: capitalize;
  padding: 8px 30px;
  border-radius: 3px;
  color: white;
  border: 0.5px solid white;
  background-color: transparent;
  cursor: pointer;
}

.darkBook .outlined__book_btn {
  font-weight: 400;
  font-size: clamp(13px, 1vw, 15px);
  line-height: 17px;
  outline: none;
  /* identical to box height */
  text-transform: capitalize;
  padding: 8px 30px;
  border-radius: 3px;
  color: var(--grey600);
  border: 0.5px solid var(--grey600);
  background-color: transparent;
  cursor: pointer;
}

.outlined__book_btn:hover {
  background-color: var(--sblue);
  color: var(--pwhite);
  border: 0.5px solid var(--sblue);
}
