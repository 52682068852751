.seprator__gap {
  margin: 20px 0 30px;
}

.department__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.department__form span {
  color: var(--error);
}

.department__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px !important;
}
