.books__right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;
  text-align: center;
  max-width: 500px;
  padding: 50px;
}

.books__right h1 {
  color: white;
  margin-bottom: 20px;
}

.darkBook .books__right h1 {
  color: white;
  margin-bottom: 20px;
}
.books__righthead img {
  width: 100%;
}

.books__righthead h4 {
  margin: 0 0 20px;
  font-size: clamp(24px, 1vw, 26px);
}

.books__right h1,
.books__right h4,
.books__right h5 {
  color: white;
}

.darkBook .books__right h1,
.darkBook .books__right h4,
.darkBook .books__right h5 {
  color: var(--grey600);
}

.books__descp {
  margin: 35px 0 15px;
}

.books__points {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 35px 0 0px;
}

.books__points img {
  height: 40px;
}

.outlined__book_btn {
  font-family: "Montserrat", sans-serif;
}
