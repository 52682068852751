#completion {
  width: 30vw;
  height: 35vh;
  /* margin: auto;
  display: block; */
}
.completedCourse {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  padding: 0px 70px 50px;
  background-color: white;
}

.completedCourse__container {
  height: 100%;
 overflow-y: auto;
  max-height: calc(100vh - 70px);

  padding-top: 70px;
}

.completedCourse h1 {
  font-size: 70px;
  color: #51bba0;
  font-weight: 800;
  margin-bottom: 10px;
}
.completedCourse h3 {
  color: var(--grey400);
  font-weight: 400;
}

@keyframes hideshow {
  0% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.2;
  }
  15% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

#cirkel {
  animation: hideshow 0.8s ease;
}

#check {
  animation: hideshow 0.8s ease;
}

#stars {
  animation: hideshow 1.6s ease;
  opacity: 0.9;
  /* animation-iteration-count: 5; */
}

@keyframes hideshow {
  0% {
    transform: scale(0.3);
    transform-origin: initial;
  }
  100% {
    transform: scale(1);
    transform-origin: initial;
  }
}

@keyframes draaien {
  0% {
    transform: rotate(40deg);
    transform-origin: initial;
  }
  100% {
    transform: scale(0deg);
    transform-origin: initial;
  }
}

#check {
  animation: draaien 0.8s ease;
}

@keyframes transparant {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#check {
  animation: transparant 2s;
}

.st0 {
  fill: #51bba0;
  fill-opacity: 0.8;
}
.st1 {
  fill: #51bba0;
  fill-opacity: 0.1;
}
.st2 {
  fill: #51bba0;
}

.completed__breadcrumbshome {
  border-radius: 50%;
  width: 22px;
  border: 1.5px solid var(--pblue);
  height: 22px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.completed__breadcrumbshome:hover {
  background-color: var(--pblue);
}

.completed__breadcrumbs {
  align-self: flex-start;
  margin-top: -50px;
}

.completed__breadcrumbs {
  display: flex;
  align-items: center;
  gap: 10px;
}
