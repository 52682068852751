.adminRolesCards {
  width: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid var(--grey200);
  padding: 20px;
}

.adminRolesCards h5 {
  margin-top: 8px;
  margin-left: 35px;
  color: var(--grey400);
}

.adminRolesCards__head {
  display: flex;
  align-items: center;
  gap: 1px;
}

.accessRoles__cont {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
