.accessCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 20px;
  border-radius: 20px;
  min-width: 235px;
  border: 0.5px solid var(--grey300);
}

.accessCards img {
  max-width: 30px;
  height: 30px;
}
