.activitymodal {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.activitymodal__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activitymodal__head p {
  padding: 9px 12px;
  background: linear-gradient(94.23deg, #3361cf 0%, #8a73db 101.54%);
  border-radius: 5px;
  font-size: 13px;
  color: white;
}

.activitymodal__head h4 {
  color: var(--pblue);
}
.activitymodal__head div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.activitymodal__head img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
}
