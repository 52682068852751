.rewards_counter {
  padding: 12px 0px;
  background: linear-gradient(180deg, #3361cf 0%, #8a73db 100%);
  max-width: max-content;
  font-size: 54px;
  font-weight: 600;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  min-width: 250px;
  text-align: center;
}

.bg__rewards {
  background-color: white;
   /* margin-top: 30px; */
}

.mact {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 15px;
  padding-top: 40px;
}

.rewards__head {
  font-size: 27px;
  margin-top: -20px;
  text-align: center;
}

.rewards__descp {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  color: var(--pblue);
}

.cart__qty {
  background-color: var(--sstar);
  padding: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  right: -10%;
  top: -20%;
}

/* redeemStatus */

.redeemStatus {
  padding: 20px 0px 120px;
}
