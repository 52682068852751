.assignmultiple {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.assignmultiple__users {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 150px;
  overflow-y: auto;
  padding-right: 30px;
  margin-top: 25px;
  padding: 20px;
  border: 1px solid var(--grey200);
  border-radius: 10px;
}
.assignmultiple__userData {
  display: flex;
  justify-content: space-between;
}

.assignmultiple__userData h5 {
  margin-bottom: 5px;
}

.assignmultiple__role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  height: 35px;
  border: 1px solid var(--grey200);
  border-radius: 10px;
  max-width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.assignmultiple__roleActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  height: 35px;
  background-color: var(--slightpurple);
  border: 1px solid var(--pblue);
  border-radius: 10px;
  max-width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.assignmultiple__check {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin-top: 20px; */
}

.assignmultiple__small {
  font-size: 12px;
  margin-top: 2px;
}

.assignmultiple__actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  /* margin-top: 20px; */
}
