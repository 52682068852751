.nav {
  height: 70px;
  z-index: 1202;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background: rgba(68, 68, 68, 0.05);
  backdrop-filter: blur(13px);
  position: fixed;
  width: 100%;
  max-width: 100vw;
}

.nav:before {
  content: "";
  position: absolute;
  left: -100%;
  bottom: 0;
  top: 0;
  right: 100%;
  background-color: var(--slightgrey);
  z-index: 2;
}

.nav__container {
  position: static;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 116px;
}

.nav__logo img {
  max-width: 180px;
}

.nav__tabs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.nav__tabsbtn {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey100);
  width: 120px;
  height: 36px;
  color: var(--grey400);
  border-radius: 5px;
  /* font-size: 16px; */
  cursor: pointer;
}
/* .dark .nav__tabsbtn:hover, */
.nav__tabsbtn:hover {
  background-color: var(--pblue);
  color: var(--pwhite);
}

/* .dark .nav__tabsbtn {
  background-color: var(--grey500);
  color: var(--grey200);
} */

.nav__search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__right {
  display: flex;
  gap: 24px;
  align-items: center;
}
.nav__tabsbtnactive {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 36px;
  border-radius: 5px;
  /* font-size: 16px; */
  cursor: pointer;
  background-color: var(--pblue);
  color: white;
}

.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Montserrat", sans-serif !important;
  /* font-size: 16px !important; */
}
.nav__profile,
.nav__icons {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.nav__profileControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.avatar__arrow {
  margin-left: 15px;
  transform: rotate(-90deg);
  stroke-width: 2px;
  /* stroke: var(--slightgrey); */
  margin-bottom: 5px;
  color: var(--grey400);
}

.nav__profilepic {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50%;
  padding: 3px;
  width: 35px;
  height: 35px;
  border: 2px solid var(--success);
}

.nav__iconsbg {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--pwhite); */
  /* background: rgba(255, 255, 255, 0.5); */
  border-radius: 50%;
  cursor: pointer;
   /* padding: 7px; */
  /*font-size: 15px !important; */
  position: relative;
}
.nav__icontext {
  position: absolute;
  top: 84px;
   left: 50%;
  transform: translate(-50%, -50%);
}
.comment-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width:100%;
  min-width: 100px;
   max-width: 350px;
  background-color: white;
  padding:15px 8px ;
  border-radius: 5px;
  font-size: 10px;
   position: relative;
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;}

   .comment-box div{
  display: flex;
   align-items: center ;
   flex-direction: row;
   justify-content: center;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
 
}
.comment-box span{
 
    color: var(--pblue);
    font-size: 12px;

}

 
.comment-box::before {
  content: "";
  position: absolute;
  top: -4px;
  left: 45%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid white;
}



.nav__notifyiconsbg {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--pwhite); */
  background: white;
  border-radius: 50%;
  cursor: pointer;
  padding: 7px;
  font-size: 15px !important;
  position: relative;
}
.nav__notifyiconsbg path {
  stroke: var(--grey600);
}

/* .nav__iconsbg path {
  stroke: var(--grey600);
} */



.nav__Icons1 {
  position: relative;
}

.nav__Icons1:hover .nav__IconsH1, .nav__iconsbg:hover  .nav__IconsH1{
  display: none;
  visibility: hidden;
}

.nav__IconsH1 {
  position: absolute;
  top: 0;
}

.nav__iconsbg:hover {
  scale: 1.1;
   transition: scale 0.1s linear;
 }
 .nav__icontext{
  display: none;
 }
.nav__Icons{
  position: relative;
}
.nav__Icons{
  position: relative;
}

.nav__iconsbg:hover .nav__icontext, .nav__icontext:hover {
  display: unset;
}
/* .dark .nav__iconsbg {
  background-color: var(--grey500);
  color: var(--grey200);
} */

.nav__seprator {
  flex-grow: 120px;
}

.nav__seprator p {
  width: 0.5px;
  height: 60px;
  margin: auto;
  background-color: var(--grey400);
}

.nav__notif_count {
  position: absolute;
  padding: 1.5px;
  top: 1px;
  right: 1px;
  background-color: red;
  color: white;
  height: 14px;
  width: 14px;
  font-size: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
