.module {
  display: flex;
  background-color: var(--pwhite);
  /* height: 100vh; */
  position: relative;
  max-width: 440px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 0px 0px 20px;
  height: 100%;
  height: cal(100vh - 90px);
  margin-top: 70px;
}

.module__topnotopen {
  margin: 50px 25px;
}
.module__topnotopen .module__top {
  margin: 0px 0px 40px;
  overflow-y: auto;
  padding-left: 20px;

  width: 40px;
}
.module__content {
  margin: 40px 20px 20px 40px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
   height: 80vh;
   /* opacity: 1; */
}

.modulemin .module__content {
  margin: 50px 25px;
}
.module__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module__progress {
  margin: 12px 0 30px;
}

.module__chapActive {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* margin: 30px 0 10px; */
  cursor: pointer;
  color: var(--grey500);
}

.module__chap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-left: 55px;
  /* margin: 30px 0 10px; */
  cursor: pointer;
  color: var(--grey500);
}

.module__active {
  color: var(--pmain) !important;
}
.module__chap h3:hover {
  color: var(--pmain);
}
.css-1cmhkvq-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px !important;
}
.module__topics {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 23px;
  gap: 15px;
  color: var(--grey500);
}
.module__active:hover h5{
  color: var(--pmain);
}
.module__topicsdiv {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: var(--grey500);
  cursor: pointer;
}

.module__topicsActive {
  color: var(--pmain);
}

.module__topics h4:hover {
  cursor: pointer;
  color: var(--pmain);
}

.module__accordian {
  margin-left: auto;
}

.module__switch {
  position: absolute;
  top: 45%;
  right: -15px;
  background-color: var(--grey300);
  border-radius: 50%;
}
