.editbooks__left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editbooks {
  display: grid;
  grid-template-columns: 1fr auto;
  align-content: flex-start;
  gap: 30px;
  margin-top: 20px;
}

.editbooks__right {
  width: 190px;
  height: 175px;
  border: 1.5px dashed var(--grey300);
  border-radius: 5px;
  padding: 15px;
  position: relative;
}

.editbooks__points h4 {
  font-weight: 900;
  color: white;
  text-align: center;
  font-size: 16px;
}

.editbooks__right h6 span {
  color: var(--error);
}

.editbooks__right img {
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 160px;
  object-fit: cover;
  overflow: hidden;
  margin-top: 4px;
  border-radius: 3px;
}

.editbooks__save {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
  width: 100%;
  gap: 15px;
}

.editbooks__save button {
  width: max-content;
}

.editbooks__point_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ee723333;
  width: fit-content;
  color: #ee7233;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 15px;
}

.editbooks__point_badge h5 {
  color: #ee7233;
  font-weight: 600;
}

.editbooks__cross {
  position: absolute;
  right: 20px;
  top: 45px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.editbooks__cross:hover {
  scale: 1.1;
}

.editbooks__container {
  padding-right: 15px;
  overflow-x: hidden;
}
.editbooks__addLevels {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 60px;
}
.editbooks__accordians {
  max-width: 2000px;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  height: 56px;
  transition: height 0.6s;
}

.editbooks__accordiansActive {
  max-width: 2000px;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  height: max-content;
  transition: height 0.6s;
}

.editbooks__accHead {
  background-color: var(--slightpurple);
  max-width: 2000px;
  padding: 18px 40px;
  border-radius: 10px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
}
.editbooks__accHeadOpen {
  background-color: var(--slightpurple);
  max-width: 2000px;
  padding: 18px 40px;
  border-radius: 10px 10px 0px 0px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
}

.editbooks__more {
  position: absolute;
  right: 37px;
  cursor: pointer;
  top: 10px;
  z-index: 10;
}

.edit_svg_level path {
  stroke-width: 1px;
}

.editbooks__accHead h5 {
  color: var(--pblue);
}

.editbooks__accordiansForm {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropzone-wrapper {
  border: 1px dashed var(--grey400);
  border-radius: 10px;
  margin-top: 10px;
  color: var(--grey300);
  width: 60%;
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  /* top: 50px; */
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #f5f5f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.editbooks__actions {
  margin-left: auto;
}

.editbooks__levels {
  margin-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 25px;
}

.editbooks__levelAction {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  align-items: center;
}

.editbooks__badge_select_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  margin-top: 35px;
}
.editbooks__badge_templates {
  width: 40%;
  border: 0.1px solid #c5c5c5;
  border-radius: 10px;
}
.editbooks__badge_editor {
  width: 60%;
  border: 0.1px solid #c5c5c5;
  border-radius: 10px;
  padding: 20px;
}

/* .batch {
  height: calc(100vh - 150px);
  overflow-y: auto;
} */

.dropzone-desc-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  gap: 10px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 170px;
  cursor: pointer;
  opacity: 0;
}
