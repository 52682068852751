.bookLayout__right {
  position: absolute;
  right: 0px;
  top: 70px;
}
.bookLayout__right img,
.bookLayout__left img {
  max-width: 15vw;
}

.bookLayout__left {
  position: absolute;
  left: 0;
  bottom: -5px;
}
.bookLayout__rightJungle {
  position: absolute;
  right: -25%;
  top: 10px;
  animation: pushRight 3s;
}

@keyframes pushRight {
  from {
    right: 0px;
    z-index: 999;
  }
  to {
    right: -25%;
    z-index: 0;
  }
}

.bookLayout__leftJungle {
  position: absolute;
  left: -35%;
  bottom: -5px;
  animation: pushLeft 3s;
}
@keyframes pushLeft {
  from {
    left: 0px;
    z-index: 999;
  }
  to {
    left: -35%;
    z-index: 0;
  }
}

.bookLayout__rel {
  position: relative !important;
  width: 100vw;
}
