.coursePdf{
    display: flex;
    flex-direction: column;
    /* gap: 14px; */
    width: 200px;
    background-color: var(--grey100);
    border: 1px solid var(--grey200);
    border-radius: 5px;
    cursor: pointer;

}
.coursePdf img{
    width: 80px;
    
 }
 .coursePdfImg{ display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.coursePdf:hover{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.coursePdf__card{
    height: 40px;
    border-radius: 0px 0px 5px 5px;
    width: calc(100% - 40px) ;
    padding: 8px 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coursePdf__card h5 {
    width: 100%;
    max-width: 150px;
    display: inline-block; /* Changed to inline-block to truncate to one line */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds an ellipsis (...) when text overflows */
}

 
   
  