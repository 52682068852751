.security {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.security div h6,
.security div h4 {
  margin-bottom: 3px;
}

.security__action_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 0;
}
