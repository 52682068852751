.tableUi {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tableUi__top,
.tableUi__name {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tableUi__top h1 {
  text-transform: capitalize;
}

.tableUi__actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.tableUi__actionsbtn {
  color: var(--pblue);
  background-color: var(--pwhite);
  padding: 8px 25px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  text-transform: capitalize;
}

.tableUi__actionsbtn img path {
  fill: blue !important;
}

.tableUi__sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.tableUi__Activeht {
  height: 160px;
  top: 0;
}
.tableUi__rank {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.tableUi__head .MuiTableCell-root {
  color: var(--pblue) !important;
  font-weight: 500;
  background-color: var(--slightpurple);
}
.MuiTableHead-root {
  background-color: white;
  border: 0px;
}

.tableUi___body {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: 3px solid transparent;
}

.row__spacing {
  margin: 10px 0 !important;
}

.MuiTableCell-root {
  font-size: 15px !important;
  padding: 10px !important;
}
