@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}

.boxWaves {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  height: 94px;
  --boxSize: 6px;
  --gutter: 5px;
  width: calc((var(--boxSize) + var(--gutter)) * 35);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background: var(--error);
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}
