.editcourses__left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editcourses__points {
  background-color: var(--pblue);
  padding: 10px 10px;
  border-radius: 10px;
  max-width: 210px;
  border: 0.5px solid var(--grey300);
  margin-left: auto;
  margin-top: -15px;
}
.editcourses__points h4 {
  font-weight: 900;
  color: white;
  text-align: center;
  font-size: 16px;
}
.editcourses {
  display: grid;
  grid-template-columns: 1fr auto;
  align-content: flex-start;
  gap: 30px;
  margin: 20px 0 0px;
}
.editcourses__save {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}

.editcourses__moduleAction {
  display: flex;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 15px;
  margin-right: 5px;
  align-items: center;
}

.editcourses__right {
  width: 190px;
  height: 175px;
  border: 1.5px dashed var(--grey300);
  border-radius: 5px;
  padding: 15px;
  position: relative;
}

.editcourses__right h6 span {
  color: var(--error);
}

.editcourses__right img {
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 160px;
  object-fit: cover;
  overflow: hidden;
  margin-top: 4px;
  border-radius: 3px;
}

.editcourses__cross {
  position: absolute;
  right: 20px;
  top: 45px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.editcourses__cross:hover {
  scale: 1.1;
}

.editcourses__container {
  padding-right: 15px;
}

.editcourses__addLevels {
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.editcourses__accordians {
  max-width: 2000px;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  height: 56px;
  transition: height 0.6s;
}

.editcourses__accordiansActive {
  max-width: 2000px;
  border: 1px solid var(--grey300);
  border-radius: 10px 10px 0px 0px;
  height: max-content;
  transition: height 0.6s;
}

.editcourses__accHead {
  background-color: var(--slightpurple);
  max-width: 2000px;
  padding: 18px 40px;
  border-radius: 10px 10px 0px 0px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editcourses__accHead h5 {
  color: var(--pblue);
}

.editcourses__accordiansForm {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropzone-wrapper {
  border: 1px dashed var(--grey400);
  border-radius: 10px;
  margin-top: 10px;
  color: var(--grey300);
  width: 60%;
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  /* top: 50px; */
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #f5f5f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.editcourses__actions {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: fit-content;
  margin: 15px 0;
  margin-left: auto;
}

.editcourses__levels {
  margin-top: 30px;
  padding-bottom: 50px;
}

.editcourses__showAddModule {
  margin-left: auto;
}

.editcourses__showAddTopic {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.editcourses__next {
  margin-left: auto;
}

.editCourses__point_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ee723333;
  width: fit-content;
  color: #ee7233;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 15px;
}

.editCourses__point_badge h5 {
  color: #ee7233;
  font-weight: 600;
}
