.TaskAcc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 30px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: 0.6s height ease-in-out;
}

.TaskAccActive {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  max-height: 500px;
  /* overflow-y: auto; */
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: 0.6s height ease-in-out;
}
.TaskAcc__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TaskAcc h4,
.TaskAccActive h4 {
  color: var(--grey500);
  font-weight: 600;
}
.TaskAcc h5,
.TaskAccActive h5 {
  margin: 17px 0;
}
.TaskAcc h4 span,
.TaskAccActive h4 span {
  font-weight: 500;
}

.TaskAcc__img {
  width: 350px;
  border-radius: 5px;
  object-fit: cover;
}

.TaskAcc__detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 1;
  animation: appear 1.3s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.TaskAcc__detail img {
  max-height: 200px;
  max-width: 100%;
  border-radius: 5px;
}
.TaskAcc__actions {
  justify-self: flex-end;
  display: flex;
  gap: 20px;
  align-self: flex-end;
}
