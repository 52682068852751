.workspace {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.generic__cards {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  gap: 35px;
  padding-bottom: 45px;
  margin-top: 30px;
  padding: 15px;
}
