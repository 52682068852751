.leaderboard__table_header .MuiTableCell-root {
  color: var(--pblue) !important;
  font-weight: 500;
  background-color: var(--slightpurple);
}

.leaderboard__table_body tr.unranked > td {
  border-top: 0.1px solid #c3c3c3;
  border-bottom: 0.1px solid #c3c3c3;
}

.leaderboard__points_pill {
  background-color: #3361cf;
  color: white;
  border-radius: 5px;
  width: fit-content;
  padding: 4px 14px;
  font-size: 14px;
  margin: auto;
}

.leaderboard__name_avatar {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
 

.leaderboard__pagination_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
}
