.cp__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin: 40px 0;
}

.cp__timelineprofilecircle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  transform: translateY(-50%);
}

.cp__badgescont,
.cp__timeline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 60px;
  width: 100%;
}

.cp__timeline {
  border-bottom: 3px solid #3361cf;
  position: relative;
  margin: 40px 0;
}

.cp__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.cp__item p {
  color: white;
}

.dark .cp__item p {
  color: var(--grey600);
}

.cp__badgepointscont div {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.cp__badgescont img {
  width: 100px;
}

.cp__badgepointscont {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  gap: 90px;
}
