.workspace {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.workspace__cards {
  display: flex;
  /* justify-content: center; */
  position: relative;
  flex-wrap: wrap;

  gap: 35px;
  padding: 10px;
  padding-bottom: 45px;
}

.workspace__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workspace__actions {
  display: flex;
  gap: 10px;
}

.wsDrawerActive {
  background-color: white;
  width: 100px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -1px;
  gap: 1px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.workspace__more {
  position: absolute;
  right: 1px;
  cursor: pointer;
}

.wsDrawerActive {
  background-color: white;
  width: 100px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -2px;
  gap: 1px;
}

.depDrawer {
  display: none;
  height: 0px;
}

.wsDrawerActive p {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
}

.wsDrawerActive p:hover {
  width: 80px;
  border-radius: 2px;
  background-color: var(--slightpurple);
}
