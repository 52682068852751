.completedBook {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  height: 88vh;
  padding: 9vh 0 3vh;
}

.completedBook img {
  width: 200px;
}
.completedBook h1 {
  font-size: 70px;
  font-weight: 200;
}

.completedBook h1,
.completedBook h4,
.completedBook h6,
.completedBook h5,
.completedBook h3,
.completedBook p,
.completedBook span {
  color: white;
}

.completedBook__actions {
  display: flex;
  gap: 80px;
}

.completedBook__info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 90vw;
}

.completedBook__rating {
  display: flex;
  gap: 10px;
}

.darkBook .completedBook h1,
.darkBook .completedBook h4,
.darkBook .completedBook h6,
.darkBook .completedBook h5,
.darkBook .completedBook h3,
.darkBook .completedBook p,
.darkBook .completedBook span {
  color: var(--grey500);
}
