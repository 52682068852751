.depBooks {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  padding-bottom: 50px;
  margin-top: 10px;
}

.depBooksCards {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0.5px solid var(--grey300);
  border-radius: 5px;
  height: 255px;
  width: 200px;
  padding-bottom: 20px;
  transition: height 0.5s;
  /* overflow: hidden; */
}

.depBooksCardsActive {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0.5px solid var(--grey300);
  border-radius: 5px;
  height: 100%;
  width: 200px;
  padding-bottom: 20px;
  transition: height 0.5s;
  overflow: hidden;
}
.depBooksCards img,
.depBooksCardsActive img {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 150px;
}

.depBooksCards__content {
  padding: 10px;
}

.depBooksCards__content h5 {
  font-weight: 600;
  padding-bottom: 5px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.depBooksCards .depBooksCards__content p {
  font-size: 13px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.depBooksCardsActive .depBooksCards__content p {
  font-size: 13px;
  padding-top: 10px;
}

.depBooksCards__content {
  /* height: 100px; */
  width: 170px;
  /* overflow: hidden; */
}

.depBooksCards__more {
  color: var(--pblue);
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}
