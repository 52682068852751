.level__left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  max-width: 330px;
  text-align: center;
}

.level__left h1,
.level__left h5 {
  color: var(--grey100);
}

.darkBook .level__left h1,
.darkBook .level__left h5 {
  color: var(--grey600);
}

.level__left img {
  width: 100%;
  max-width: 290px;
  max-height: 290px;
  object-fit: contain;
}
.rotate__container {
  position: relative;
}

.rotate {
  width: 290px;
  height: 290px;
  border-radius: 50%;
  animation: rotate 8s infinite linear;
  transform-origin: 50% 51.1%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate__action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rotate__waves {
  position: absolute;
  top: 49%;
  /* left: 50%;
  transform: translate(-50%, -50%); */
}

.play-pause-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Sports {
  position: relative;
  width: 100%;
  max-width: 400px !important;
  max-height: 400px !important;
  animation: splash 2s ease-in-out;
}

@keyframes splash {
  0% {
    left: -15vw;
    top: 40vh;
  }
  25% {
    left: 10vw;
    top: -20vh;
  }
  50% {
    left: -10vw;
    top: 15vh;
  }
  75% {
    left: 6vw;
    top: -8vh;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.shakespeare {
  background-color: #f7f2e9;
  width: 280px;
  height: 450px !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
}

.shakespeare img {
  border-radius: 5px;
  max-width: 280px !important;
  object-fit: cover;
  max-height: 380px !important;
  height: 400px !important;
  /* max-height: 400px !important; */
}

.shakespeare h5 {
  margin: 10px;
  font-size: 13px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shakespeare__icon {
  position: absolute;
  width: 100px !important;

  left: -20px;
  top: -20px;
}
.shakespeare__container {
  position: relative;
  /* width: 270px; */
}
/* mindful */

.mindful__preStart {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mindful__preStart span {
  color: var(--pblue);
  font-weight: 600;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  animation: appear 1s linear 1.2s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mindful__preStart h6 {
  color: var(--grey400);
  font-weight: 500;
  position: relative;
  animation: upSlide 1.2s linear;
}

@keyframes upSlide {
  from {
    top: 50px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}

.mindful__gif {
  width: 100%;
  max-width: 490px;
  height: 490px;
  object-fit: cover;
}
