.account {
  /* display: flex;
  flex-direction: column;
  gap: 10px; */
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 0px 5vw 0px 5vw;
  background-color: #ffffff;
}

.account__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 !important;
}

.account__mb20 {
  margin-bottom: 15px;
}

.account__lefttop {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
  max-width: 1000px;
}

.account__lefttop h1 {
  text-transform: capitalize;
}
.account__lefttop h3 {
  color: var(--grey500);
}

.sperator__space {
  margin: 40px 0;
}
.account__avtar {
  position: relative;
}
.account__edit {
  position: absolute;
  left: 72.35%;
  right: 21.96%;
  top: 76.39%;
  bottom: 17.05%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--pblue);
  border: 1px solid #ffffff;
  cursor: pointer;
}

.account label.filebutton {
  width: 120px;
  height: 40px;
  overflow: hidden;
  position: relative;
  background-color: #ccc;
}

.account__input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}

.account__details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.account__profile {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  gap: 8px;
}

.account__profilecontents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1vw;
  max-width: 1420px;
  margin: 30px 0;
}
.account__profilecard {
  background-color: #e6e6e688;
  width: 100%;
  max-width: 23vw;
  padding: 10px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
}

.account__profilecontents img {
  height: 18px;
}
