.reportsDetailed__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px;
}

.reportsDetailed__headright {
  display: flex;
  align-items: center;
  gap: 10px;
}
.reportsDetailed__headright div {
  font-size: 14px;
}
.reports__table {
  width: 100%;
  overflow: auto;
}

.reports__selectLayout{
  position: relative;
}

.reports__selectActive {
  background-color: white;
  border: 0.5px solid var(--grey300);
  width: 250px;
  border-radius: 3px;
  z-index: 99;
  height: 200px;
  overflow-x: hidden;
   overflow-y: auto;
  display: flex;
  padding: 5px 0px;
  position: absolute;
  flex-direction: column;

  gap: 20px;
  transition: height 1s;
}
.reports__select {
display: none;  overflow: hidden;
}
.reports__selectcontent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 5px;
  padding: 0px 0px 0px 10px;
}

.reports__sort {
  font-size: 13px;
}
