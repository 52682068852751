.noRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
  margin: auto;
}

.noRecord img {
  width: 250px;
  animation: example 1s;
}
@keyframes example {
  0% {
    width: 100px;
  }
  50% {
    width: 350px;
  }
  100% {
    width: 250px;
  }
}
