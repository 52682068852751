.cart{
    height: calc(100vh - 70px);
    overflow-y: auto;
 }

.cart__layout{
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 20px;
}

.cart__left{
    height: 360px;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.cart__leftAction{
 display: flex ;
justify-content: flex-end ;
margin-top: 30px !important;
}



/* checkout */

.checkout{
    background-color: white;
    border-radius: 8px;
    border: 1px solid var(--grey300);
    padding: 30px 20px;
 }


.checkout__cal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
}
 .checkout h5{
    color: var(--pblue);
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 15px;
}
.checkout__seprator{
    height: 1px;
    width: 100%;
    background-color: var(--grey300);
}




.checkout__gift{
    background-color: #FDF2C9;
    padding: 20px 30px 30px 15px;
    border-radius: 10px;
    position: relative;
    box-shadow: rgb(204, 219, 232, 0.7) 2px 2px 4px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;}

.checkout__gift h6{
    font-size: 13px;
    margin-top: 15px;
    font-weight: 500;
    line-height: 1.7;
 }
.checkout__gift Span{
    color: transparent;
    margin-right: 12px;
    border-radius: 2px !important;
    width: 18px;
     height: 18px;
     border: 1px solid var(--pblue);
 
    
}
 
.checkout__gift div{
    color: var(--pblue);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.checkout__gift h6 span{
    color: var(--pmain);
    font-weight: 500;
    font-size: 13px;
    border: none;
    margin-right: 0;
}

.checkout__bow{
    position: absolute;
    right: 0%;
    top: 0%;
    /* margin-top: -20px; */
}


/* checkout page */


.checkout__head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout__sperator{
    height: 1px;
    background-color: var(--grey300);
    width: 100%;
 }

.checkout__modal{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.checkout__address{
color: var(--pblue);
display: flex;
align-items: center;
gap: 5px;
}

.checkout__head h6{
    color: rgb(143, 143, 143);
}
.checkout__modal h5{
    margin-top: 15px;
}

.checkout__modal h6 span{
font-size: 12px;
color: var(--pmain);
font-weight: 600;
}

.cartModal__searchLayout{
    position: absolute;
    width: 100%;
    z-index: 15;
 }

 .cartModal__searchDSelected{
    width: calc(100% - 25px) ;
    background-color: white;
    padding: 12px;
    border: 1px solid var(--grey300);
    display: flex;
    gap: 25px;
    border-radius: 5px;
    /* justify-content: space-around; */
    align-items: center;
}

.cartModal__searchD{
    width: calc(100% - 27px) ;
    background-color: white;
    padding: 12px;
    border: 1px solid var(--grey300);
    border-top: none;
    display: flex;
    gap: 25px;
    /* border-radius: 5px; */    
    /* justify-content: space-around; */
    align-items: center;
}        
.cartModal__searchD:hover, .cartModal__searchDSelected{
    cursor: pointer;
    background-color: var(--slightpurple);
}
.cartModal__searchDSelected{
    margin-top: 20px;
}
.cartModal__searchD h6, .cartModal__searchDSelected h6{
    color: var(--pblue);
    font-weight: 500;
}
.cartModal__searchD p, .cartModal__searchDSelected p{
     font-weight: 500;
     font-size: 12px;
}

.cartModal__searchDetails{
    display: flex;
     justify-content: space-between;
    align-items: center;
    gap: 10px;
}


/* thank you css */

.thanks{
  background-color: white;
    height: calc(100vh - 70px);
    width: 100vw;
    display: flex;
    justify-content: center;
    gap: 10vh;
    align-items: center;
    flex-direction: column;

}

.thanks__correct{
    border: 5px solid var(--sgreen);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;   
     height: 70px;
    border-radius: 50%;
}

.thanks h1{
    font-size: 1.2rem;
    color: var(--sgreen);
}
.thanks__layout{
    padding-top: 70px ;
}