.personal {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.personal__select div:first-child {
  padding: 0 !important;
}

.personal__page_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.personal__phone_input {
  padding: 8px;
  border: 1px #c3c3c3 solid;
  border-radius: 5px;
  margin-top: 4px;
  width: -webkit-fill-available;
}

.personal__phone_input input {
  border: none;
  outline: none;
  width: 100%;
}

.personal__form_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 35px 50px;
  padding: 10px 0;
}
.personal__action_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 0;
}
