.booksLevels {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  max-width: 503px;
}

.booksLevels__head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.booksLevels__larrow {
  transform: rotate(90deg);
  width: 18px;
  cursor: pointer;
}
.booksLevels__larrow path {
  fill: white;
  cursor: pointer;
}
.booksLevels__ans div {
  display: flex;
  gap: 5px;
  align-items: center;
}
.booksLevels__rarrow {
  transform: rotate(-90deg);
  width: 18px;
  cursor: pointer;
}

.booksLevels__ans {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
}

.booksLevels__que {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  margin: 0px 20px 15px;
}

.booksLevels h1,
.booksLeveldetails h4,
.booksLeveldetails h5 {
  color: white;
}

.darkBook .booksLevels h1,
.darkBook .booksLeveldetails h4,
.darkBook .booksLeveldetails h5 {
  color: var(--grey600);
}

.levelresponsive__img {
  width: 100%;
}

.booksLeveldetails {
  height: 400px;
  overflow-y: auto;
  padding-right: 10px;
  overflow-x: hidden;
  margin: 35px 0;
  max-width: 510px;
}

.booksLevels_sepratorarrow {
  margin: 10px 0px;
}

.booksLevels__head img path {
  fill: #fff;
}

.booktask {
  /* margin-bottom: 20px; */
  background-color: white;
  border-radius: 15px;
  padding: 25px;
}
.booktask__content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.booktask__content h4 {
  text-align: center;
  color: var(--grey400);
}
.booktask__actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.booktask__head {
  display: flex;
  /* align-self: flex-end; */
  gap: 5px;
}

.booktask__head div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.booktask__head div {
  gap: 5px;
}
.booktask__head h5 {
  color: var(--grey400) !important;
}

.booktask__close {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1300px) {
  .booksLeveldetails {
    max-height: 380px;
  }
  .booktask {
    margin-bottom: 0px;
  }
}

.booksLevels img {
  max-height: 90px;
}
