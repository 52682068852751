.MuiTab-root {
  text-transform: none;
}

.notifs_container {
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 5vh 10vw 5vh 10vw;
  background-color: #ffffff;
}

.notifs_settings_container {
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 5vh 20vw 5vh 20vw;
  background-color: #ffffff;
}

.notifyMB__badge {
  margin-left: 25px;
}

.notifyBlock__btn {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  font-family: inherit;
  padding: 16px 8px;
}

.notifyBlock__btn_unseen {
  /* background-color: var(--slightpurple); */
  background-color: #f2f6fcdd;

  font-weight: 500;
}
.notifyBlock__btn_unseen caption {
  color: var(--pblue);
}
.notifyBlock__btn:hover {
  /* background-color: #3361cf11; */
  background-color: #f2f6fcdd;
  transition: all ease-in-out 0.1s;
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.notifyMB__badge .badge {
  width: 7px;
  height: 7px;
  background: red;
  border-radius: 50%;
}

.notifyMB__time p {
  font-size: x-small;
  font-weight: 600;
}

.notifyMB__day p {
  font-size: x-small;
  font-weight: 600;
}

.notify__tabSwitcher .MuiTabs-flexContainer {
  justify-content: space-around !important;
}

.notify__markseen {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.notifyMB__day p {
  font-size: 10px;
}
.notifyMB__time p {
  font-size: 10px;
}

.notifyMB__message caption {
  font-size: 13px;
}

.notifyMB__badge {
  background-color: var(--slightpurple);
  /* padding: 0px 3px; */
  border-radius: 2px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify__header h5 {
  font-size: 14px !important;
}

.settings_container {
  margin: 2rem 0;
}

.settings_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.settings_left {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 5px;
}

.settings_right {
  display: flex;
  justify-content: center;
  align-items: center;
}
