.departments {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.departments__cards {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 0.5px solid var(--grey300);
  border-radius: 5px;
  height: 255px;
  width: 250px;
  max-width: 250px;
  position: relative;
}

.departments__cards:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.departments__actions {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}
.departments__cardActions {
  display: flex;
  justify-content: space-evenly;
}

.departments__cards img, .departments__addDept {
  border-radius: 5px 5px 0 0;
  width: 250px;
   height: 150px;
   object-fit: cover;
}
.departments__addDept{
  background-color: var(--grey200);
display: flex;
justify-content: center;
align-items: center;  
}
.departments__addDepth6{
  height: 1px;
margin: -5px 15px 0px;
width: 88%;
background-color: var(--grey200)
 }

 
.departments__cards h6 {
  padding: 15px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey600);
}
.departments__cardsActions {
  padding: 0 15px;
  margin-top: auto;
  margin-bottom: 13px;
}
.departments__more {
  position: absolute;
  right: 1px;
  cursor: pointer;
}

.depDrawerActive {
  background-color: white;
  width: 100px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -1px;
  gap: 1px;
}

.depDrawer {
  display: none;
  height: 0px;
}

.depDrawerActive p {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
}

.depDrawerActive p:hover {
  width: 80px;
  border-radius: 2px;
  background-color: var(--slightpurple);
}
