.reqAuth {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: white;
}

.login__left h1 {
  margin-bottom: 20px;
}

.login__img {
  position: absolute;
  overflow-y: hidden;
  margin-top: -20%;
  animation: mymove 20s linear infinite;
}

@keyframes mymove {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}

.login__overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: saturate(180%) blur(100px);
  overflow: hidden;
}

.login__left,
.login__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.login__left h1 {
  text-align: center;
  color: var(--grey200);
}

.login__leftimage {
  margin-top: 40px;
}

.login__leftartwork {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 50px;
}

.login__form {
  margin-top: 30px;
  max-width: 400px;
  width: 100%;
}

.password__input__wrapper {
  position: relative;
}

.password__visibility__icon {
  position: absolute;
  top: 42%;
  right: 4%;
  cursor: pointer;
}

.login__input {
  background-color: var(--grey600);
  margin-top: 15px;
  width: 100%;
  max-width: 380px;
  padding: 15px 10px;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  outline: none;
  color: var(--pwhite);
  border-style: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.login__input:focus {
  outline: 0.5px solid white;
}

.login__invalid {
  outline: 1px solid red;
}

.login__error {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #ffd6d6;
  border-radius: 5px;
  color: red;
  width: 100%;
  max-width: fit-content;
  padding: 2px 8px;
}

.login__error span {
  color: red;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 400;
  padding-left: 5px;
}

.login__checkbox {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.login__checkbox input {
  outline: 1px solid var(--pwhite);
}
.login__checkbox input[type='checkbox'] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid var(--pwhite);
  accent-color: var(--pwhite);
  color: var(--grey600);
  background: var(--grey600);
}

.login__checkbox label {
  margin-left: 10px;
  font-size: 13px;
  color: var(--pwhite);
}

.login__forgot {
  margin-top: 15px;
  font-size: 13px;
  cursor: pointer;
  border: none;
  color: var(--pwhite);
}
.login__forgot:hover {
  color: var(--syellow);
}

.login__forgot__disabled {
  margin-top: 15px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  color: grey;
  cursor: not-allowed;
}

.login__powered {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 300;
  color: var(--pwhite);
}
.login__powered a {
  text-decoration: none;
  color: var(--pwhite);
}

.login__powered b {
  font-weight: 400 !important;
  cursor: pointer;
  border-bottom: 1px solid var(--pwhite);
}
.fpassword {
  margin-top: 20px;
}

.fpassword h4,
.fpassword h5 {
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  color: var(--pwhite);
}

.fpassword__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
}

.login__send {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login__send img {
  margin: 30px 0;
}

@media screen and (max-width: 912px) {
  .disableTablet {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .login__leftartwork {
    margin-bottom: 90px;
    margin-right: 40px;
    width: 60px;
  }
  .login__powered {
    font-size: 14px;
  }
  .login__rightimg {
    width: 55%;
  }
  .login__right {
    justify-content: space-evenly;
    height: 80vh;
    align-items: center;
  }
  .login__form {
    max-width: 350px;
  }
  .fpassword h4,
  .fpassword h5 {
    max-width: 350px;
  }
  .login__input {
    max-width: 330px;
  }
}

@media screen and (max-width: 320px) {
  .login__form {
    max-width: 250px;
  }
  .fpassword h4,
  .fpassword h5 {
    max-width: 250px;
  }
  .login__input {
    max-width: 230px;
  }
}

@media screen and (min-width: 2520px) {
  .login__form {
    max-width: 650px;
  }
  .fpassword h4,
  .fpassword h5 {
    max-width: 650px;
    margin-top: 25px;
  }
  .login__input {
    max-width: 630px;
  }
  .login__powered {
    font-size: 27px;
  }
  .login__error span {
    font-size: 20px;
  }
  .login__checkbox label {
    font-size: 18px;
  }

  .login__forgot {
    font-size: 18px;
  }
  .login__left h1 {
    font-size: 70px;
  }
  h5 {
    font-size: 22px;
  }
  .login__img {
    width: 100vw;
  }

  .login__checkbox,
  .login__input,
  .login__forgot {
    margin-top: 25px;
    font-size: 25px;
  }
  .fpassword__footer {
    width: 650px;
  }
  .login__left h1 {
    margin-bottom: 30px;
  }
}

.login__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.login__remforgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login__or {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0px;
  gap: 5px;
}


.small__seprator{
  height: 0.5px;
  width: 70px;
  background-color: white;
}


.login__or p{
  color: white;
}

.login__social{
cursor: pointer;

}
.login__social span:hover{
scale: 1.1;
}