.addTasks {
  background-color: #3361cf11;
  padding: 25px;
  border-radius: 10px;
  width: 96%;
}

.addTasks h6 {
  color: var(--pblue);
  margin: 20px 0;
}

.task__points {
  margin-top: 10px;
}
.task__points h4 {
  font-size: 13px;
}

.task__actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
