.create__form {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.create__formhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.create__form h5 {
  margin-top: 15px;
}
.create__form h5 span,
.create__split h5 span {
  color: var(--error);
}

.createcourse__action {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin: 20px auto 0px;
}

/* drag drop */
.box {
  position: relative;
  /* background: #ffffff; */
  width: 100%;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 10px;
}

.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.dropzone-wrapper {
  border: 1px dashed var(--grey400);
  border-radius: 10px;
  margin-top: 10px;
  color: var(--grey300);
  position: relative;
  height: 120px;
  width: 100% !important;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 50%;
  /* top: 50px; */
  font-size: 16px;
}
.dropzone-image {
  display: flex;
  justify-content: center;
  padding: 30px;
  /* align-items: center; */
  height: 90%;
  width: 100%;
}

.dropzone-image img {
  position: relative;
  max-width: 400px;
  margin-top: 5px;
  border-radius: 3px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #f5f5f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.discard {
  display: unset;
}
.discard:hover {
  display: none;
}
.discardActive {
  display: none;
}

.discard:hover {
  display: unset;
}
.icon__container{
  position: relative;
}

.icon-container img:nth-child(2) {
  /* display: none; */
  opacity: 0;
  position: absolute;
 }

.icon-container:hover img:nth-child(1) {
  display: none;
  opacity: 0;
  position: relative;


}

.icon-container:hover img:nth-child(2) {
  /* display: block; */
  opacity: 1;
  cursor: pointer;
}

.create__split {
  display: flex;
  gap: 10px;
  width: 100%;
}
