.credential__parent_container {
  display: flex;
  justify-content: start;
  align-items: end;
  width: 100%;
  padding: 10px 0px;
}

.credential__tier_left {
  position: relative;
  width: 3%;
  height: 100%;
}

.credential__tier_step {
  width: 97%;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.credential__tier_left_border {
  height: 90px;
  border-left: 0.1px dashed #3361cf;
  margin-left: 30%;
}

.credential__tier_left_border::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #3361cf content-box;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.credential__tier_right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 14px;
}

.credential__tier_badge {
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 90%;
  padding: 0 0 5px 43px;
  position: relative;
  gap: 10px;
}

/* TODO: position based on badge */
.credential__tier_badge_border {
  border-left: 0.1px dashed #3361cf;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 52px;
  z-index: -1;
}

.credential__tier_badge_text {
  font-size: 12px;
  color: #3361cf;
  font-weight: 600;
}

.credential__tier_tasks_count {
  width: 100%;
  height: 35px;
  background-color: #3361cf33;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.credential__tier_tasks_count p {
  font-size: 12px;
  padding-left: 30px;
}

.credential__tier_title {
  font-size: 14px;
  color: #3361cf;
  word-wrap: break-word;
  display: inline-block;
  margin-bottom: 5px;
  width: max-content;
  font-weight: 600;
}
