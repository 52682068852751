.task__head {
  display: flex;
  gap: 20px;
  align-items: center;
}

.task__headprofile {
  position: relative;
}
.task__headnotf {
  position: absolute;
  background-color: var(--error);
  /* padding: 2px; */
  color: white;
  /* font-size: 8px; */
  width: 19px;
  height: 19px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 11px;
  font-weight: 700;
  top: 3px;
  border-radius: 50%;
}

.task__head h3 {
  color: var(--grey500);
  font-weight: 500;
}

.task__head h4 {
  margin-top: 3px;
  color: var(--grey400);
}

.taskdetHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

/* cards */

.taskDet__books {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.taskDet__books h6 {
  color: var(--pblue);
}

.taskDet__bookshead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.taskDet__bookscard {
  width: 5.5vw;
  height: 25px;
  /* padding: 3px 0px; */
  background-color: var(--pblue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.taskDet__bookscardIA {
  width: 5.5vw;
  height: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.taskDet__bookscontent {
  display: flex;
  gap: 1px;
  border-radius: 5px;
  border: 5px solid var(--grey200);
}

.taskDet__bookscard caption {
  color: white;
  font-size: 15px;
}
.taskDet__bookscardIA caption {
  color: var(--pblue);
  font-size: 15px;
}

.taskdetHead__cards {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-top: 3vh;
  /* overflow-y: auto;
  height: calc(100vh - 280px); */
  padding-right: 10px;
}
