.forum__link {
  list-style-type: none;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.forum__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.forum__icon img {
  height: 23px;
}
