.quest__search {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 20px !important;
  border: 1px solid var(--grey300) !important;
  border-radius: 5px;
}

.quest__search_mini {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 20px !important;
  border: 1px solid var(--grey300) !important;
  border-radius: 5px;
}

.quest__search input,
.quest__search input:focus {
  width: 300px;
  border: none;
  outline: none;
  font-weight: 100 !important;
  color: var(--grey500);
  font-size: 13px !important;
}

.quest__search input::placeholder {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.quest__search img {
  height: 18.5px;
}
