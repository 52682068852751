.credentials__CredentialList {
  display: flex;
  flex-direction: column;

  padding-bottom: 40px;
  max-width: 50%;
}

.credentials__CredentialList > div {
  border-bottom: 0.1px solid #c3c3c3;
}

.credentials__CredentialList > div:last-child {
  border-bottom: none;
}

.credentials__CredentialTier {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 30px 0;
}
