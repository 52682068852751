.react-calendar {
  width: 300px;
  color: var(--grey400);
  font-weight: 500 !important;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  padding: 15px;
}
.react-calendar div abbr[title] {
  text-decoration: unset !important ;
}

.reportsDetailed__headright div {
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none !important;
}

.react-calendar__tile {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--grey400);
}

.react-calendar__tile:hover {
  background-color: var(--slightpurple) !important;
}

.react-calendar__tile--active {
  background-color: #d6dff5;
  color: var(--pblue);
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: var(--grey300);
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:focus {
  background-color: #d6dff5;
  color: var(--pblue);
  font-weight: 600 !important;
}
.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:disabled .MuiSvgIcon-root {
  color: var(--grey300) !important;
}
.react-calendar__navigation button:disabled {
  border: 0.1px solid var(--grey300) !important;
}

.react-calendar__navigation button:disabled:hover {
  background-color: transparent !important;
}

.react-calendar__navigation__label {
  order: 0;
  justify-self: flex-start;
}
.react-calendar__navigation__label:hover {
  background-color: transparent !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  order: 1;
  border: 0.5px solid var(--pblue) !important;
  border-radius: 30px 0px 0px 30px !important;
}
.react-calendar__navigation__next-button {
  order: 2;
  border: 0.5px solid var(--pblue) !important;
  border-radius: 0px 30px 30px 0px !important;
}

.react-calendar__navigation {
  height: 28px;
}

.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__prev-button:hover {
  background-color: var(--slightpurple) !important;
}
