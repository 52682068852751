.accessRoles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0 20px;
}

.accessRoles__content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  /* width: 100%; */
}
.accessRoles__head {
  background-color: var(--slightpurple);
  color: var(--pblue);
  width: 35vw;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 15px;
}
