.profileHead {
  display: flex;
  justify-content: flex-end;
}
.profileTop {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.profileTop__role {
  margin-top: 15px;
  color: var(--pblue);
  font-size: 13px;
}

.profileTop h1 {
  color: var(--grey500);
}
.profileTop__cong {
  justify-self: flex-end;
  align-self: flex-end;
  margin-left: auto;
}

.profileModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profileModal__seprator {
  height: 1px;
  width: 100%;
  background-color: var(--grey100);
  margin: 20px 0px;
}

.profileModal__achivements {
  width: 100%;
  height: 250px;
  border: 5px;
  position: relative;
  background-color: var(--slightpurple);
}
.profileTop__tab {
  position: absolute;
  left: 15px;
  padding: 10px;
  color: white;
  font-size: 14px;
  border-radius: 0px 0px 7px 7px;
  background: linear-gradient(to right, #3c63d0, #8071da);
}
