.cc__container {
  display: flex;
  justify-content: stretch;
  gap: 24px;
  margin: 40px 0;
  flex-grow: 1;
  flex-wrap: wrap;
}

.cc__card {
  max-width: 250px;
  width: 250px;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  position: relative;
}

.cc__card img {
  width: 250px;
  aspect-ratio: 16/9;
}

.cc__card_body {
  padding: 10px;
}

.cc__card_body p {
  font-size: 16px;
  margin: 0px 0 14px 0;
}

.cc__card_body span {
  font-size: 13px;
  margin: 20px 0 0 0;
}

.cc__separator {
  margin: 10px 0;
}

.cc__downloadbtn {
  background-color: #c5c5c5aa;
  border-radius: 5px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 0;
  cursor: pointer;
}

.cc__downloadbtn:hover {
  background-color: #959595;
}
