.addTopics h6 {
  color: var(--pblue);
  margin: 15px 0;
}

.topic__points {
  margin-top: 45px;
}

.topic__actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.addTopics__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.addTopics__form h4 {
  margin-bottom: 3px;
}

.ql-editor .ql-editor-handle {
  background-color: gray;
  width: 8px;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  opacity: 1;
  cursor: col-resize;
  z-index: 10;
}
