.books__left {
  border-radius: 10px;
  /* box-shadow: 0px 7.99074px 7.99074px rgba(0, 0, 0, 0.1);
  border-radius: 15.9815px;
  padding: 40px 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}
.books__left h1 {
  text-transform: uppercase;
  color: white;
}
.books__left img {
  height: 100%;
  max-height: 400px;
  max-width: 400px;
}

.books__left h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: white;
  line-height: 24px;
}
