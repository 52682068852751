.pb__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin: 40px 0;
}

.pb__timelineprofilecircle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  transform: translateY(-50%);
}

.pb__badgescont,
.pb__timeline {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.pb__timeline {
  border-bottom: 1px dashed white;
  position: relative;
  margin: 80px 0;
}

.dark .pb__timeline {
  border-bottom: 1px dashed #747474;
}

.pb__timelinecomplete {
  border-bottom: 10px solid #ffbe18;
  /* width: 600px; */
  position: absolute;
  left: 0;
  margin: 80px 0;
}

.pb__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.pb__item p {
  color: white;
}

.dark .pb__item p {
  color: var(--grey600);
}

.pb__badgepointscont div {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.pb__badgescont img {
  width: 100px;
}

.pb__badgepointscont {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  gap: 50px;
}
