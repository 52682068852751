body {
  overflow: hidden;
}

.wheel {
  position: relative;
  /* left: 50%;
  transform: translate(-50%, -50%); */
  height: 380px;
  width: 380px;
  /* border: 185px solid rgba(206, 206, 219, 0.7); */
  border-radius: 50%;
  /* box-shadow: inset var(--grey400) 0px 0px 0px 1.5px,
    var(--grey400) 0px 0px 0px 1.5px; */
  box-shadow: rgba(0, 0, 0, 0.2) 12px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 4px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  transition: 0.1s ease-in-out;

}


.quest__current {
  position: absolute;
  transition: 0.5s;
  top: 225px;
  bottom: 0;
  left: 225px;
  right: 0;
}
.quest__centerProgess{
  display: unset;
}
.dark .quest__centerProgess{
  display: none;
}
.quest__centerProgessDark{
  display: none;


}
.dark .quest__centerProgessDark{
  display: unset;

}

.dark .wheel {
  position: relative;

  /* position: absolute;
  left: 50%;
  transform: translate(-50%, -50%); */
  height: 380px;
  width: 380px;
  /* border: 185px solid rgb(51, 97, 207, 0.4); */
  border-radius: 50%;
  /* box-shadow: inset var(--grey400) 0px 0px 0px 1.5px,
    var(--grey400) 0px 0px 0px 1.5px; */
  box-shadow: rgba(0, 0, 0, 0.2) 12px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 4px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  transition: 0.1s ease-in-out;

}


/* For dark background */

.card {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 140px;
 
  background-repeat: no-repeat;
  position: absolute;
  transition: 0.5s;
}
 
 


.cardColor{
   
   clip-path: polygon(
    6.5% 13.47%,
    10.53% 11%,
    14.52% 8.56%,
    18.62% 6.77%,
    22.63% 4.68%,
    27.03% 3.13%,
    32.73% 1.11%,
    36.92% 1%,
    40.64% 0.21%,
    45.49% 0.21%,
    49.89% 0.21%,
    53.84% 0%,
    55.81% 0.61%,
    60.43% 0.61%,
    64.95% 1.65%,
    67.9% 1.84%,
    71.97% 3.05%,
    76.04% 4.27%,
    80.22% 5.81%,
    84.39% 8.31%,
    88.57% 10.82%,
    91.72% 12.71%,
    94.88% 14.6%,
    97.8% 18.59%,
    98.81% 21.56%,
    98.2% 24.84%,
    96.77% 29.58%,
    95.11% 36.25%,
    93.42% 42.48%,
    91.65% 49.04%,
    89.96% 54.81%,
    88.22% 60.55%,
    87.26% 65.27%,
    85.53% 71.43%,
    84.18% 76.66%,
    82.64% 82.36%,
    81.1% 88.06%,
    79.68% 92.16%,
    78.25% 96.27%,
    76.04% 97.68%,
    73.19% 96.12%,
    70.99% 94.58%,
    68.46% 93.25%,
    65.18% 91.38%,
    55.9% 89.5%,
    59.01% 89.78%,
    58.67% 90.23%,
    56.71% 89.96%,
    52.09% 90.04%,
    47.48% 90.49%,
    42.69% 90.95%,
    37.92% 91.51%,
    32.22% 94.01%,
    27.98% 95.94%,
    25.34% 97.15%,
    23.11% 98.16%,
    21.5% 97.15%,
    20% 92.23%,
    17.9% 85.87%,
    16.56% 81.79%,
    15.42% 77.18%,
    14.05% 72.48%,
    12.7% 67.4%,
    11.23% 62.21%,
    10.02% 57.06%,
    8.9% 52.73%,
    8.04% 48.92%,
    6.68% 43.93%,
    4.99% 38.55%,
    3.84% 34.13%,
    2.47% 28.25%,
    1.23% 24.68%,
    1.45% 20.66%,
    3.34% 16.2%,
    1.33% 17.65%
  ); 
  animation: appear 0.5s linear;

}


.cardColorOrg,  .dark .cardColorOrg{
  animation: appear 0.5s linear;
}
 




.dark .cardColor {
  background: linear-gradient(169.39deg, #ffffff 6.34%, #dddddd 161.99%);
}

.next {
  color: magenta;
}
.prev {
  color: green;
}

.active {
  color: yellow;
}

.quest__wheellayout {
  position: relative;
  height: 460px;
  padding: 40px;
  overflow: hidden;
}

.quest__prevwheellayout {
  position: relative;

  height: 470px;
  padding: 40px;
  overflow: hidden;
}
.quest__prevcurrent {
  position: absolute;
  transition: 0.5s;
  top: 285px;
  bottom: -10%;
  left: 35%;
  right: 0;
}

.quest__prevcurrentPreview {
  position: absolute;
  transition: 0.5s;
  top: 221px;
  bottom: -10%;
  left: 27%;
  right: 0;
}


.home__zoom {
  animation: zoom 1.3s ease-in-out;
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.card img{
  position: relative;
}

.card__locked{
  position: absolute;
  top: 20%;
}


@keyframes appear {
  0%{
    opacity:0;
    scale: 0.5;
  }
  100%{
    opacity:0.5;
    scale: 0.1;
  }
}