/* Timeline Container */
.task_badge__timeline {
  background: var(--primary-color);
  margin: 100px auto;
  padding: 20px;
  width: 90%;
  position: relative;
}

/* Card container */
.task_badge__card {
  position: relative;
}

/* setting padding based on even or odd */
.task_badge__card:first-child {
  padding: 0 0 0 0px !important;
}

.task_badge__card:last-child {
  padding: 50px 0 50px 0px !important;
}

.task_badge__card:nth-child(odd) {
  padding: 150px 0 150px 0px;
}
.task_badge__card:nth-child(even) {
  padding: 150px 0px 150px 0;
}
/* Global ::before */
.task_badge__card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: dashed #ffffff;
  z-index: 0;
}

.dark .task_badge__card::before {
  border: dashed var(--grey400);
}

/* Setting the border of top, bottom, left */
.task_badge__card:nth-child(odd)::before {
  left: 0px;
  top: -4.5px;
  bottom: -4.8px;
  border-width: 2px 0 2px 2px;
  border-radius: 80px 0 0 80px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
  .task_badge__card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
  }
}

/* Setting the border of top, bottom, right */
.task_badge__card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 2px 2px 2px 0;
  border-radius: 0 80px 80px 0;
}

/* Removing the border if it is the first card */
.task_badge__card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

/* Removing the border if it is the last card  and it's odd */
.task_badge__card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.task_badge__card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

.task_badge__bdcont {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  z-index: 100;
}

.task_badge__timelinebadge {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin solid #747474;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.task_badge__badge {
  cursor: pointer;
  width: 150px;
  position: absolute;
  right: 0;
  transform: translate(35%, -10%);
  transition: all ease-in-out 0.3s;
}

.task_badge__timelineprofilecircle {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.task_badge__superbadge {
  width: 150px;
  height: 100%;
}

.task_badge__superbadgecont_1 {
  position: absolute;
  right: 0;
  top: 150px;
  transform: translate(21%, -50%);
  z-index: 10;
}

.task_badge__superbadgecont_2 {
  position: absolute;
  left: 0;
  top: 450px;
  transform: translate(-21%, -50%);
  z-index: 10;
}

.task_badge__superbadgecont_2 img {
  order: 1;
}

.task_badge__superbadgecont_2 .task_badge__superbadgemetadata {
  order: 2;
}

.task_badge__superbadgecont_3 {
  position: absolute;
  right: 0;
  top: 450px;
  transform: translate(21%, -50%);
  z-index: 10;
}

.task_badge__superbadgecont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  gap: 24px;
}

.task_badge__superbadgemetadata {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.dark .task_badge__superbadgemetadata p {
  color: var(--grey600);
}

.task_badge__tier_label {
  font-size: 14px !important;
}

.task_badge__name {
  font-size: 18px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.task_badge__superbadgemetadata p {
  font-size: 20px;
  color: white;
}
.task_badge_lock{
  position: absolute;
  top: -90%;
  right: -5%;
  z-index: 99;
  /* left: 260px; */
 }

.task_badge__superbadgeprogress {
  width: 250px;
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.task_badge__lockLayout{
  position: relative;
}
.task_badge__superbadgecompleted {
  max-width: 250px;
  height: 10px;
  background-color: #ffa318;
  border-radius: 10px;
  position: absolute;
  left: 0;
}

.task_badge__subicon {
  width: 30px;
  margin-left: 10px;
}
