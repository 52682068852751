button {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}
.rewards_cat_btn {
  padding: 10px 50px;
  border: 1px solid #d9d9d9;
  outline: none;
  background-color: transparent;
  color: #5c5c5c;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  margin: 8px 0;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rewards_cat_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  background-color: #3361cf26;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin: 0 0 12px 0;
}

.rewards_cat_header p {
  padding: 0 50px;
  color: #3361cf;
}

.rewards_cat_btn div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.rewards_cat_btn.active {
  background-color: #3361cf !important;
  color: white !important;
}

.rewards_cat_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  padding: 10px 0 50px;
}

.rewards_cards_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 0 28px 0;
}

.rewards_product_img {
  width: 185px;
  height: 150px;
  flex-shrink: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.rewards_card {
  position: relative;
  outline: 1px solid #c3c3c3;
  border-radius: 10px;
  flex-shrink: 0;
  max-width: min-content;
}

.rewards_card .card_body {
  padding: 4px 12px 12px;
}

.reward_name {
  font-size: 14px;
  color: #444444;
  font-weight: medium;
}

.reward_points {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.rewards__manage_rewards_dd_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 3px 15px 3px 0;
  background-color: var(--pwhite);
  border-radius: 5px;
  border: 0.5px solid var(--grey400);
  cursor: pointer;
  z-index: 1;
  transition: height 0.5s;
}

.open_rm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 3px 15px 3px 0;
  background-color: var(--pwhite);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 0.5px solid var(--grey400);
  border-bottom: none;
  cursor: pointer;
  z-index: 1;
  transition: height 0.5s;
}

.rewards__manage_rewards_dd_item p {
  /* font-size: 14px; */
  padding: 8px 17px;
  cursor: pointer;
  color: var(--grey400);
  text-transform: capitalize;
}
.rewards__manage_rewards_dd_item p:not(:first-child):hover {
  background-color: #3361cf11;
}

.rewards_cat_form {
  gap: 20px;
  display: grid;
}

.rewards_prod_form {
  gap: 20px;
  display: grid;
}

.rewards_cat_form label {
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 500;
}

.rewards_divider {
  width: 100%;
  border: 0.1px solid #c3c3c3;
  margin: 20px 0;
}

.required_star {
  color: var(--error);
}
