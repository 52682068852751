.error {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  min-height: 80vh;
  max-height: calc(100vh - 200px);
  overflow: hidden;
}

.error__left {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.error img {
  max-width: 400px;
  animation: example;
}
@keyframes example {
  from {
    width: 0px;
  }
  to {
    width: 400px;
  }
}
.error__left p {
  background-color: var(--grey200);
  padding: 3px 10px;
  width: 120px;
}
.error__left h1 {
  font-size: 80px;
  height: 100%;
}
.error__left h2 {
  font-size: 28px;
}
